<template>
  <div class="financial-recurrence">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova recorrência</span>
    </button>
    <b-table
      :data="allFinancialRecurrences"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="type"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <b-table-column
        field="type"
        label="Categoria"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.subcategory.category.name.toUpperCase() }} ({{
          props.row.subcategory.name.toUpperCase()
        }})</b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ parseFloat(props.row.value).toFixed(2) }}</small>
                <br />
                <strong>Descrição:</strong>
                <small>{{
                  props.row.description ? props.row.description : 'N/A'
                }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            isCreateMovementModalActive = true
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCreateMovementModalActive" :width="640" scroll="keep">
      <div class="card" style="height: 700px">
        <div class="card-content">
          <div class="content">
            <b-field label="Selecione a data">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="model.date"
                inline
              >
              </b-datepicker>
            </b-field>
            <b-field label="Tipo">
              <b-select
                placeholder="Selecione um tipo"
                required
                expanded
                v-model="model.type"
              >
                <option
                  v-for="(item, index) in ['Despesa', 'Receita']"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field>
              <button
                class="button field is-info is-light"
                @click="createMovement"
              >
                <span>Salvar</span>
              </button>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-field label="Descrição">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="model.description"
              ></b-input>
            </b-field>
            <b-field label="Categoria">
              <b-select
                placeholder="Selecione uma categoria"
                required
                expanded
                v-model="financialCategoryId"
                @input="changeCategory"
              >
                <option
                  v-for="(item, index) in allFinancialCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Subcategoria">
              <b-select
                placeholder="Selecione uma subcategoria"
                required
                v-model="model.financial_subcategory_id"
                expanded
              >
                <option
                  v-for="(item, index) in allFilterFinancialSubCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/financial-recurrences/store/service'
import serviceRecipe from '../features/recipes/store/service'
import serviceExpense from '../features/expenses/store/service'
import FinancialRecurrence from '../models/financial-recurrence'

export default {
  name: 'FinancialRecurrences',
  data() {
    return {
      model: FinancialRecurrence.model,
      showDetailIcon: false,
      isCardModalActive: false,
      isCreateMovementModalActive: false,
      allFilterFinancialRecurrence: [],
      allFilterFinancialSubCategories: [],
      financialCategoryId: null,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('financialRecurrences', ['allFinancialRecurrences']),
    ...mapState('financialCategories', ['allFinancialCategories']),
    ...mapState('financialSubCategories', ['allFinancialSubCategories']),
  },
  methods: {
    ...mapActions('financialRecurrences', ['getAllFinancialRecurrences']),
    ...mapActions('financialCategories', ['getAllFinancialCategories']),
    ...mapActions('financialSubCategories', ['getAllFinancialSubCategories']),
    async init() {
      await this.getAllFinancialRecurrences()
      await this.getAllFinancialCategories()
      await this.getAllFinancialSubCategories()
    },
    changeCategory(category) {
      this.allFilterFinancialSubCategories =
        this.allFinancialSubCategories.filter(
          (x) => x.financial_category_id === category
        )
      console.log(this.allFilterFinancialSubCategories)
    },
    onEdit(item) {
      item.value = parseFloat(item.value).toFixed(2)

      if (item.subcategory) {
        this.financialCategoryId = item.subcategory.category.id
        item.financial_subcategory_id = item.subcategory.id
        this.changeCategory(this.financialCategoryId)
      }

      this.model = { ...item }
    },
    async save() {
      const financialRecurrence = { ...this.model }

      financialRecurrence.value = financialRecurrence.value.replace('R$ ', '')
      financialRecurrence.value = financialRecurrence.value.replace(',', '')

      try {
        financialRecurrence.id
          ? await service.updateFinancialRecurrences(financialRecurrence)
          : await service.saveFinancialRecurrences(financialRecurrence)
        await this.$success('Recorrência')
        location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async createMovement() {
      const financialRecurrence = { ...this.model }

      financialRecurrence.value = financialRecurrence.value.replace('R$ ', '')
      financialRecurrence.value = financialRecurrence.value.replace(',', '')

      financialRecurrence.date = this.$moment(financialRecurrence.date).format(
        'YYYY-MM-DD'
      )

      delete financialRecurrence.category
      delete financialRecurrence.subcategory
      delete financialRecurrence.id

      console.log(financialRecurrence)

      try {
        if (financialRecurrence.type.toUpperCase() === 'RECEITA') {
          delete financialRecurrence.type
          await serviceRecipe.saveRecipe(financialRecurrence)
        } else {
          delete financialRecurrence.type
          await serviceExpense.saveExpense(financialRecurrence)
        }

        // await this.$success('Recorrência')
        // location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a recorrência?')

        if (result.isConfirmed) {
          await service.deleteFinancialRecurrence(id)

          await this.$delete('Recorrência')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>